import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import ModalComp from "../../../../components/ModalComp/ModalComp";
import {delay} from "../../../../utils/functions";

export default function ModalAddElementInOffer({
                                                   categories,
                                                   data,
                                                   setData,
                                                   modalIsOpen,
                                                   setModalIsOpen,
                                                   updateTotal,
                                                   eventType,
                                                   showModalPrefillOffer,
                                                   setShowModalPrefillOffer,
                                                   showModalUpdateOffer,
                                                   setShowModalUpdateOffer,
                                                   event,
                                                   categ = '',
                                                   index = null,
                                                   pri = 0,
                                                   initCost = 0,
                                                   initQty = 0,
                                               }) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [category, setCategory] = useState()
    const [element, setElement] = useState()
    const [staffSelect, setStaffSelect] = useState('')
    const [price, setPrice] = useState(0)
    const [qty, setQty] = useState(0)
    const [cost, setCost] = useState(0)
    const [type, setType] = useState('per_event')
    const [priceAlcoholic, setPriceAlcoholic] = useState(0)
    const [itemsOptions, setItemsOptions] = useState([])
    const [foodMenus, setFoodMenus] = useState([])
    const [drinkMenus, setDrinkMenus] = useState([])
    const [staffCategories, setStaffCategories] = useState([])
    const [staffUsers, setStaffUsers] = useState([])
    const [logistics, setLogistics] = useState([])
    const [servicePackages, setServicePackages] = useState([])
    const [taxes, setTaxes] = useState([])
    const {context} = useOutletContext()

    useEffect(() => {
        getFoodMenus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        setQty(initQty)
        setCost(initCost)
        setCategory(categ)
        if (categ !== '' && data[data.indexOf(data.find(item => item.category === categ))])
            setElement(data[data.indexOf(data.find(item => item.category === categ))].elements[index].value)
        else
            setElement(0)
        setPrice(pri)
        switch (categ) {
            case 'food':
                setItemsOptions(foodMenus.filter(item => item.event_types.includes(eventType)))
                break;
            case 'drink':
                setItemsOptions(drinkMenus.filter(item => item.event_types.includes(eventType)))
                break;
            case 'staff':
                setItemsOptions(staffUsers)
                break;
            case 'logistics':
                setItemsOptions(logistics)
                break;
            case 'service_packages':
                setItemsOptions(servicePackages)
                break;
            case 'taxes':
                setItemsOptions(taxes.filter(item => item.event_types.includes(eventType)))
                break;
            default:
                setItemsOptions([])
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIsOpen])

    const getFoodMenus = async () => {
        axios.get(process.env.REACT_APP_API + '/food-menus/list-all',
            {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                var items = [];
                response.data.menus.forEach((item) => {
                    if (item.is_custom !== true)
                        items.push({
                            value: item.id,
                            label: item.title,
                            price: item.price,
                            items: item.items,
                            event_types: item.event_types.map(item => item.title),
                            vat: item.vat.value
                        })

                })


                setFoodMenus(items)
                getDrinkMenus()

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getDrinkMenus = async () => {
        axios.post(process.env.REACT_APP_API + '/drink-menus/list/0',
            {}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                var items = [];
                response.data.menus.forEach((item) => {
                    items.push({
                        event_types: item.event_types.map(item => item.title),
                        value: item.id,
                        label: item.title,
                        price: item.price_non_alcoholic,
                        items: item.items,
                        price_alcoholic: item.price_alcoholic,
                        vat: item.vat.value,
                        vat_non_alcoholic: item.vat_non_alcoholic.value
                    })

                })
                setDrinkMenus(items)
                getStaffCategories()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getStaffCategories = () => {
        axios.get(process.env.REACT_APP_API + '/staff-categories', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title,
                        vat: 19
                    })
                })
                setStaffCategories(items)
                getStaff()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getStaff = () => {
        axios.post(process.env.REACT_APP_API + '/staff-users/list/0', {search: ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.staff.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.name,
                        staff_category_id: item.staff_category.id,
                        phone: item.phone,
                        cost: item.cost,
                        price: item.price,
                        vat: 19
                    })
                })
                setStaffUsers(items)
                getProducts()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getProducts = async () => {
        axios.post(process.env.REACT_APP_API + '/products/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.products.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.title,
                        price: item.price,
                        unit: item.unit,
                        vat: 19
                    })
                })
                setLogistics(options)
                getTaxes()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getTaxes = async () => {
        axios.get(process.env.REACT_APP_API + '/taxes', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].percent !== true)
                        temp.push({
                            value: response.data[i].id,
                            label: response.data[i].title,
                            type: response.data[i].type,
                            event_types: response.data[i].event_types.map(item => item.title),
                            price: 0,
                            vat: 19
                        })
                }
                setTaxes(temp)
                getPackages()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getPackages = async () => {
        axios.post(process.env.REACT_APP_API + '/service-packages/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.packages.length; i++) {
                    temp.push({
                        value: response.data.packages[i].id,
                        label: response.data.packages[i].title,
                        totals: response.data.packages[i].totals,
                        type: response.data.packages[i].type,
                    })
                }
                setServicePackages(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const addElement = async (e) => {
        e.preventDefault()
        let indexCateg = null
        if (category !== '' && price !== 0) {

            let temp = [...data]
            let exist = temp.find(item => item.category === category)
            let currentElement = itemsOptions.find(item => item.value === element)

            if (category !== 'drink') {
                currentElement.price = price
            }
            if (category === 'logistics') {
                currentElement.type = type
            }
            if (category === 'logistics' && type === 'per_event') {
                currentElement.qty = parseInt(qty)
            }
            if (category === 'staff' || category === 'logistics') {
                currentElement.cost = cost
            }
            if (category === 'service_packages') {
                if (exist && categ !== '' && index !== null) {
                    indexCateg = temp.indexOf(exist)
                    temp[indexCateg].elements[index].price = price

                } else {
                    temp.push({
                        category,
                        category_label: categories.find(item => item.value === category).label,
                        elements: []
                    })
                    for (let m = 0; m < currentElement.totals.length; m++) {
                        temp[temp.length - 1].elements.push({
                            value: currentElement.value,
                            label: currentElement.label,
                            price: parseFloat(currentElement.totals[m].total_without_vat),
                            vat: currentElement.totals[m].vat_percent,
                            type: currentElement.type,
                        })
                    }
                }
            } else {
                if (exist) {
                    indexCateg = temp.indexOf(exist)
                    if (categ !== '' && index !== null) {
                        temp[indexCateg].elements[index].price = price
                        if (category === 'staff' || category === 'logistics') {
                            temp[indexCateg].elements[index].cost = cost
                        }

                        if (category === 'logistics' && type === 'per_event') {
                            temp[indexCateg].elements[index].qty = parseInt(qty)
                        }
                    } else {
                        if (category === 'drink') {
                            currentElement.price_alcoholic = priceAlcoholic
                        }
                        currentElement.price = price
                        if (category === 'drink') {
                            temp[indexCateg].elements.push({
                                value: currentElement.value,
                                label: currentElement.label,
                                price: currentElement.price,
                                items: currentElement.items,
                                vat: currentElement.vat_non_alcoholic
                            })
                            temp[indexCateg].elements.push({
                                value: currentElement.value,
                                label: currentElement.label + ' (cu zahăr și alcool)',
                                price: currentElement.price_alcoholic,
                                items: currentElement.items,
                                vat: currentElement.vat
                            })
                        } else {
                            temp[indexCateg].elements.push(currentElement)
                        }
                    }
                } else {
                    if (category === 'drink') {
                        temp.push({
                            category,
                            category_label: categories.find(item => item.value === category).label,
                            elements: [{
                                value: currentElement.value,
                                label: currentElement.label,
                                price: currentElement.price,
                                items: currentElement.items,
                                vat: currentElement.vat_non_alcoholic
                            }, {
                                value: currentElement.value,
                                label: currentElement.label,
                                price: currentElement.price_alcoholic,
                                items: currentElement.items,
                                vat: currentElement.vat
                            }]
                        })
                    } else {
                        temp.push({
                            category,
                            category_label: categories.find(item => item.value === category).label,
                            elements: [currentElement]
                        })
                    }
                }
            }

            setData(temp)
            context.setContentUpdated(true)
            setModalIsOpen(false)
            await delay(1000);
            updateTotal(temp, indexCateg ?? temp.length - 1)
        }
    }
    const deleteElement = async () => {
        let temp = [...data]
        let exist = temp.find(item => item.category === category)
        let index2 = null
        if (exist) {
            index2 = temp.indexOf(exist)

            if (category === 'drink' || category === 'service_packages') {

                if (temp[index2].elements.length <= 2) {
                    temp.splice(index2, 1)
                } else {
                    let menuId = temp[index2].elements[index].value
                    temp[index2].elements.splice(index, 1)
                    let exist2 = temp[index2].elements.find(item => item.value === menuId)
                    if (exist2) {
                        let index3 = temp[index2].elements.indexOf(exist2)
                        if (index3 !== -1)
                            temp[index2].elements.splice(index3, 1)
                    }
                }
            } else {
                if (temp[index2].elements.length <= 1) {
                    temp.splice(index2, 1)
                } else {
                    temp[index2].elements.splice(index, 1)
                }
            }

        }


        setData(temp)
        context.setContentUpdated(true)
        setModalIsOpen(false)
        await delay(1000);
        updateTotal(temp, index)
    }

    const prefillOffer = async () => {
        let temp = []


        if (event.food_menu_to_events.length > 0) {
            temp.push({
                category: 'food',
                category_label: categories.find(item => item.value === 'food').label,
                elements: []
            })
            for (let j = 0; j < event.food_menu_to_events.length; j++) {
                temp[temp.length - 1].elements.push({
                    "value": event.food_menu_to_events[j].food_menu.id,
                    "label": event.food_menu_to_events[j].food_menu.title,
                    "price": event.food_menu_to_events[j].custom_price ? event.food_menu_to_events[j].price : event.food_menu_to_events[j].food_menu.price,
                    "vat": event.food_menu_to_events[j].food_menu.vat.value,
                    "items": event.food_menu_to_events[j].food_menu.items
                })
            }

            updateTotal(temp, temp.length - 1)
        }

        if (event.drink_menu_to_events.length > 0) {
            temp.push({
                category: 'drink',
                category_label: categories.find(item => item.value === 'drink').label,
                elements: []
            })
            for (let j = 0; j < event.drink_menu_to_events.length; j++) {
                temp[temp.length - 1].elements.push({
                    "value": event.drink_menu_to_events[j].drink_menu.id,
                    "items": event.drink_menu_to_events[j].drink_menu.items,
                    "label": event.drink_menu_to_events[j].drink_menu.title,
                    "price": event.drink_menu_to_events[j].custom_price ? event.drink_menu_to_events[j].price_alcoholic : event.drink_menu_to_events[j].drink_menu.price_alcoholic,
                    "vat": 19
                })
                temp[temp.length - 1].elements.push({
                    "value": event.drink_menu_to_events[j].drink_menu.id,
                    "label": event.drink_menu_to_events[j].drink_menu.title,
                    "price": event.drink_menu_to_events[j].custom_price ? event.drink_menu_to_events[j].price_non_alcoholic : event.drink_menu_to_events[j].drink_menu.price_non_alcoholic,
                    "vat": 9
                })
            }
            updateTotal(temp, temp.length - 1)
        }
        if (event.products.length > 0) {
            temp.push({
                category: 'logistics',
                category_label: categories.find(item => item.value === 'logistics').label,
                elements: []
            })
            for (let j = 0; j < event.products.length; j++) {
                temp[temp.length - 1].elements.push({
                    "value": event.products[j].product.id,
                    "label": event.products[j].product.title,
                    "price": event.products[j].price,
                    "vat": event.products[j].vat_value,
                    "type": event.products[j].type,
                    "qty": event.products[j].qty,
                })
            }
            updateTotal(temp, temp.length - 1)
        }
        if (event.service_packages.length > 0) {
            temp.push({
                category: 'service_packages',
                category_label: categories.find(item => item.value === 'service_packages').label,
                elements: []
            })
            for (let j = 0; j < event.service_packages.length; j++) {
                for (let m = 0; m < event.service_packages[j].service_package.totals.length; m++) {
                    temp[temp.length - 1].elements.push({
                        "value": event.service_packages[j].service_package.id,
                        "label": event.service_packages[j].service_package.title,
                        "price": event.service_packages[j].service_package.totals[m].total_without_vat,
                        "vat": event.service_packages[j].service_package.totals[m].vat_percent,
                        "type": event.service_packages[j].type,
                    })
                }

            }
            updateTotal(temp, temp.length - 1)
        }
        if (event.staff_categories && event.staff_categories.length > 0) {
            let add = event.staff_categories.find(item => item.users_number && item.price)
            if ((event.price_staff_for_categories && add) || !event.price_staff_for_categories)
                temp.push({
                    category: 'staff',
                    category_label: categories.find(item => item.value === 'staff').label,
                    elements: []
                })
            for (let j = 0; j < event.staff_categories.length; j++) {
                if (event.price_staff_for_categories) {
                    if (event.staff_categories[j].users_number && event.staff_categories[j].price)
                        temp[temp.length - 1].elements.push({
                            "value": event.staff_categories[j].category_id,
                            "label": staffCategories.find(item => item.value === event.staff_categories[j].category_id).label,
                            "price": event.staff_categories[j].price,
                            "cost": event.staff_categories[j].cost,
                            "qty": event.staff_categories[j].users_number,
                            "vat": 19
                        })
                } else {
                    if (event.staff_categories[j].users && event.staff_categories[j].users.length > 0)
                        for (let m = 0; m < event.staff_categories[j].users.length; m++) {
                            temp[temp.length - 1].elements.push({
                                "value": event.staff_categories[j].users[m].id,
                                "label": staffUsers.find(item => item.value === event.staff_categories[j].users[m].id).label,
                                "cost": staffUsers.find(item => item.value === event.staff_categories[j].users[m].id).cost,
                                "price": event.staff_categories[j].users[m].price,
                                "vat": 19
                            })
                        }
                }

            }
            updateTotal(temp, temp.length - 1)
        }
        if (event.sheet && event.sheet.general && event.sheet.general.add_taxes && event.sheet.general.add_taxes.length > 0) {
            temp.push({
                category: 'taxes',
                category_label: categories.find(item => item.value === 'taxes').label,
                elements: []
            })
            for (let j = 0; j < event.sheet.general.add_taxes.length; j++) {
                if (event.sheet.general.add_taxes[j].percent !== true)
                    temp[temp.length - 1].elements.push({
                        "value": event.sheet.general.add_taxes[j].id,
                        "label": event.sheet.general.add_taxes[j].label,
                        "price": event.sheet.general.add_taxes[j].price,
                        "vat": 19,
                        "type": event.sheet.general.add_taxes[j].type
                    })
            }
            updateTotal(temp, temp.length - 1)
        }
        setData(temp)
        setShowModalPrefillOffer(false)
        setShowModalUpdateOffer(false)
    }
    return (
        <>
            <ModalComp
                title={intl.formatMessage({id: 'offers.add_element_in_offer'})}
                isOpen={modalIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <form onSubmit={(e) => addElement(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "offers.category"})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={categories}
                                required={true}
                                key={category}
                                disabled={categ !== ''}
                                value={category}
                                onChange={(option) => {
                                    setCategory(option.value)
                                    switch (option.value) {
                                        case 'food':
                                            setItemsOptions(foodMenus.filter(item => item.event_types.includes(eventType)))
                                            break;
                                        case 'drink':
                                            setItemsOptions(drinkMenus.filter(item => item.event_types.includes(eventType)))
                                            break;
                                        case 'staff':
                                            setItemsOptions(staffCategories)
                                            break;
                                        case 'logistics':
                                            setItemsOptions(logistics)
                                            break;
                                        case 'service_packages':
                                            setItemsOptions(servicePackages)
                                            break;
                                        case 'taxes':
                                            setItemsOptions(taxes.filter(item => item.event_types.includes(eventType)))
                                            break;
                                        default:
                                            setItemsOptions([])
                                            break;
                                    }
                                    setElement(0)
                                    setPrice(0)
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                        {category === 'staff' && <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "offers.staff_type"})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={[{
                                    value: 'categories',
                                    label: intl.formatMessage({id: 'staff.categories.title'})
                                }, {
                                    value: 'users',
                                    label: intl.formatMessage({id: 'staff.users.title'})
                                }
                                ]}
                                required={true}
                                key={index}
                                disabled={index !== null}
                                value={staffSelect}
                                onChange={(option) => {
                                    setStaffSelect(option.value)
                                    if (option.value === 'categories')
                                        setItemsOptions(staffCategories)
                                    else
                                        setItemsOptions(staffUsers)
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>}
                        {((category !== '' && category !== 'staff') || (category === 'staff' && staffSelect !== '')) &&
                            <div>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: "offers.item"})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={itemsOptions}
                                    required={true}
                                    key={index}
                                    disabled={index !== null}
                                    value={element}
                                    onChange={(option) => {
                                        setElement(option.value)
                                        setPrice(option.price)
                                        if (category === 'drink')
                                            setPriceAlcoholic(option.price_alcoholic)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>}
                        {category === 'logistics' && element !== 0 && <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "products.service_packages.type"})}<span
                                className={"form-required"}>*</span></div>
                            <Selector options={[{
                                value: 'per_event',
                                label: intl.formatMessage({id: 'settings.taxes.type.per_event'})
                            }, {
                                value: 'per_person',
                                label: intl.formatMessage({id: 'settings.taxes.type.per_person'})
                            }]}
                                      required={true}
                                      key={index}
                                      disabled={index !== null}
                                      value={type}
                                      onChange={(option) => {
                                          setType(option.value)
                                      }}
                                      isSearchable={true}
                                      isOptionDisabled={(option) => option.disabled}
                            />
                        </div>}
                        {((category === 'staff' || category === 'logistics') && element !== 0 && type === 'per_event') &&
                            <div>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: "general.qty"})}<span
                                    className={"form-required"}>*</span></div>

                                <input type={"text"} className={"form-input"}
                                       required={true} value={qty}
                                       onChange={(e) => setQty(e.target.value)}/>
                            </div>}
                        {((category === 'staff' || category === 'logistics') && element !== 0) &&
                            <div>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: "staff.cost"})}<span
                                    className={"form-required"}>*</span></div>

                                <input type={"text"} className={"form-input"}
                                       required={true} value={cost}
                                       onChange={(e) => setCost(e.target.value)}/>
                            </div>}
                        {category !== '' && element !== 0 &&
                            <div>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: "general.price"})}
                                    {category === 'taxes' ? (' (' + intl.formatMessage({
                                        id: 'settings.taxes.type.' +
                                            taxes.find(item => item.value === element).type
                                    }) + ')') : ''}<span
                                        className={"form-required"}>*</span></div>

                                <input type={"text"} className={"form-input"}
                                       required={true} value={price}
                                       onChange={(e) => setPrice(e.target.value)}/>
                            </div>}

                        {category === 'drink' && element !== 0 && categ === '' && <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "offers.price_alcoholic"})}<span
                                className={"form-required"}>*</span></div>

                            <input type={"text"} className={"form-input"}
                                   required={true} value={priceAlcoholic}
                                   onChange={(e) => setPriceAlcoholic(e.target.value)}/>
                        </div>}
                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 col-md-6 d-flex"}>
                                {categ !== '' && index !== null && <Button
                                    className={"btn-error mt-4"}
                                    value={intl.formatMessage({id: 'general.buttons.delete'})}
                                    type={'button'}
                                    onClick={() => {
                                        deleteElement()
                                    }}
                                />}
                            </div>
                            <div className={"col-12 col-md-6 d-flex"}>
                                <Button
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'general.buttons.' + (categ !== '' && index !== null ? 'save' : 'add')})}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>

            <ModalComp
                title={intl.formatMessage({id: 'offers.prefill_offer'})}
                isOpen={showModalPrefillOffer}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setShowModalPrefillOffer(false)}
            >
                <div className={"content"} style={{paddingBottom: '200px'}}>
                    <div>
                        <p>{intl.formatMessage({id: 'offers.prefill_offer_message'})}</p>
                    </div>

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-error mt-4"}
                                value={intl.formatMessage({id: 'general.no'})}
                                type={'button'}
                                onClick={() => {
                                    setShowModalPrefillOffer(false)
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                type={'button'}
                                /* disabled={saveLoading}
                                 loading={saveLoading}*/
                                onClick={() => {
                                    prefillOffer()
                                }}
                            />
                        </div>
                    </div>

                </div>


            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'offers.update_offer'})}
                isOpen={showModalUpdateOffer}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setShowModalUpdateOffer(false)}
            >
                <div className={"content"} style={{paddingBottom: '200px'}}>
                    <div>
                        <p>{intl.formatMessage({id: 'offers.update_offer_message'})}</p>
                    </div>

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-error mt-4"}
                                value={intl.formatMessage({id: 'general.no'})}
                                type={'button'}
                                onClick={() => {
                                    setShowModalUpdateOffer(false)
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                type={'button'}
                                /* disabled={saveLoading}
                                 loading={saveLoading}*/
                                onClick={() => {
                                    prefillOffer()
                                }}
                            />
                        </div>
                    </div>

                </div>


            </ModalComp>
        </>

    );
}

