import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import Selector from "../../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";

export default function EventLogistics() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const currency = localStorage.getItem('currency')
    let params = useParams();
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const [products, setProducts] = useState()
    const [servicePackages, setServicePackages] = useState()
    const [productsOptionsAll, setProductsOptionsAll] = useState([])
    const [productsOptions, setProductsOptions] = useState([])
    const [servicesPackagesOptionsAll, setServicesPackagesOptionsAll] = useState([])
    const [servicesPackagesOptions, setServicesPackagesOptions] = useState([])


    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalIsOpenServices, setModalIsOpenServices] = useState(false)
    const [product, setProduct] = useState(null)
    const [productToEvent, setProductToEvent] = useState(null)
    const [servicePackage, setServicePackage] = useState(null)
    const [servicePackageToEvent, setServicePackageToEvent] = useState(null)
    const [customPrice, setCustomPrice] = useState(false)
    const [totals, setTotals] = useState([])
    const [price, setPrice] = useState(0)
    const [cost, setCost] = useState(0)
    const [details, setDetails] = useState(0)
    const [qty, setQty] = useState(0)
    const [type, setType] = useState('per_event')
    const [vat, setVat] = useState(null)

    const {context, ended} = useOutletContext()
    const [loading, setLoading] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [showModalOutOfStockProduct, setShowModalOutOfStockProduct] = useState(false)
    const [showModalOutOfStockPackage, setShowModalOutOfStockPackage] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getProducts(response.data.products.map(item => item.product.id))
                getServicePackages(response.data.service_packages.map(item => item.service_package.id))
                if (response.data.products) {
                    setProducts(response.data.products)
                }
                if (response.data.service_packages) {
                    setServicePackages(response.data.service_packages)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getProducts = async () => {
        axios.post(process.env.REACT_APP_API + '/products/list/0', {},
            {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                var items = [];
                response.data.products.forEach((item) => {
                    items.push({
                        value: item.id,
                        label: item.title,
                        vat: item.vat.value,
                        limited_stock: item.limited_stock
                    })

                })

                setProductsOptions(items)
                setProductsOptionsAll(items)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getServicePackages = async () => {
        axios.post(process.env.REACT_APP_API + '/service-packages/list/0', {},
            {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                var items = [];
                response.data.packages.forEach((item) => {

                    items.push({
                        value: item.id,
                        label: item.title,
                        price: item.price,
                        type: item.type,
                        totals: item.totals,
                        cost: item.cost
                    })

                })

                setServicesPackagesOptions(items)
                setServicesPackagesOptionsAll(items)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    const verifyProduct = async () => {
        let payload = {
            product,
            product_to_event: productToEvent,
            type,
            qty
        }
        await axios.put(process.env.REACT_APP_API + '/events/verify-product/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1)
                    saveProduct()
                else {
                    setModalIsOpen(false)
                    setShowModalOutOfStockProduct(true)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const saveProduct = async () => {
        if (product === null) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        let payload = {
            product_to_event: productToEvent,
            product,
            price,
            cost,
            details,
            type,
            vat_value: vat,
            qty
        }
        await axios.put(process.env.REACT_APP_API + '/events/product/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpen(false)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
        setShowModalOutOfStockProduct(false)
    }
    const deleteProduct = async () => {
        let payload = {
            product_to_event: productToEvent,
        }
        await axios.post(process.env.REACT_APP_API + '/events/product/delete/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpen(false)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const saveServicePackage = async () => {

        if (servicePackage === null) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        let payload = {
            service_package_to_event: servicePackageToEvent,
            service_package: servicePackage,
            price,
            cost,
            details,
            type,
            totals,
            custom_price: customPrice
        }
        await axios.put(process.env.REACT_APP_API + '/events/service-package/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpenServices(false)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
        setShowModalOutOfStockPackage(false)
    }
    const verifyServicePackage = async (e) => {
        e.preventDefault();

        if (servicePackage === null) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        let payload = {
            service_package_to_event: servicePackageToEvent,
            service_package: servicePackage,
            type
        }
        await axios.put(process.env.REACT_APP_API + '/events/verify-service-package/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1)
                    saveServicePackage()
                else {
                    setModalIsOpenServices(false)
                    setShowModalOutOfStockPackage(true)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const deleteServicePackage = async () => {
        let payload = {
            service_package_to_event: servicePackageToEvent,
        }
        await axios.post(process.env.REACT_APP_API + '/events/service-package/delete/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpenServices(false)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const exportExcel = () => {
        setLoadingDownload(true)
        fetch(process.env.REACT_APP_API + '/event-logistics-excel/' + params.id, {
            method: 'POST',
            body: JSON.stringify({language: localStorage.getItem('language') ?? 'ro'}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "logistics.xlsx";

                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))
    }
    return (
        <>
            <Button
                className={"btn-secondary mb-2"}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.download_logistics_excel'})}
                type={"button"}

                loading={loadingDownload}
                disabled={loadingDownload}
                onClick={() => exportExcel()}
            />
            <div className="card">
                <div className={"header"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <p>{intl.formatMessage({id: 'events.menu.logistics'})}</p>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            {access && !ended && <Button
                                className={"btn-primary d-flex align-items-center ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => {
                                    let ids = products.map(item1 => item1.product.id)
                                    let temp = []
                                    productsOptionsAll.forEach(item => {
                                        if (!ids.includes(item.value)) {
                                            temp.push(item)
                                        }
                                    })
                                    setProductsOptions(temp)
                                    setModalIsOpen(true)
                                    setProductToEvent(null)
                                    setProduct(null)
                                    setPrice(0)
                                    setCost(0)
                                    setQty(0)
                                    setDetails('')
                                    setType('per_event')
                                    setVat(null)
                                }}
                            />}
                        </div>

                    </div>

                </div>
                <div className={"content"}>

                    {products.length > 0 && <div className={"table contracts mb-1"}>
                        <div className={"table-head"}>
                            <div className={"row"}>
                                <div
                                    className={"col-2 table-td"}>
                                </div>
                                <div
                                    className={"col-4 table-td"}>
                                    {intl.formatMessage({id: 'events.title'})}
                                </div>
                                <div
                                    className={"col-2 table-td"}>
                                    {intl.formatMessage({id: 'general.type'})}
                                </div>
                                <div
                                    className={"col-2 table-td"}>
                                    {intl.formatMessage({id: 'general.price'})}
                                </div>

                            </div>

                            <div className={"table-body"}>
                                {
                                    products.map((item, i) => (
                                        <div className={"row"} key={'prod' + i}
                                             style={{cursor: (access && !ended) ? "pointer" : ''}}
                                             onClick={() => {
                                                 if (access && !ended) {
                                                     setProductsOptions(productsOptionsAll)
                                                     setModalIsOpen(true)
                                                     setProductToEvent(item.id)
                                                     setProduct(item.product.id)
                                                     setPrice(item.price)
                                                     setCost(item.cost)
                                                     setQty(item.qty)
                                                     setDetails(item.details)
                                                     setType(item.type)
                                                     setVat(item.vat_value)
                                                 }
                                             }}
                                        >
                                            <div className={"col-2 table-td"}>
                                                {i + 1}.
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {item.product.title}
                                            </div>
                                            <div className={"col-2 table-td"}>
                                                {intl.formatMessage({id: 'settings.taxes.type.' + item.type})}
                                            </div>
                                            <div className={"col-2 table-td"}>
                                                {item.price}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>

                <ModalComp
                    title={intl.formatMessage({id: 'general.buttons.' + (productToEvent === null ? 'add' : 'edit')})}
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    className={"add-question-modal"}
                >
                    <form style={{height: '-webkit-fill-available'}} onSubmit={(e) => {
                        e.preventDefault()
                        let temp = productsOptions.find(item => item.value === product)
                        if (temp.limited_stock === true)
                            verifyProduct()
                        else
                            saveProduct()
                    }}>
                        <div className={"content"} style={{maxHeight: "800px", height: '90%'}}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <Selector
                                            options={productsOptions}
                                            value={product}
                                            key={product}
                                            onChange={(option) => {
                                                setProduct(parseInt(option.value))
                                                setVat(option.vat)
                                            }}
                                            isSearchable={true}
                                            isOptionDisabled={(option) => option.disabled}
                                            disabled={productToEvent !== null}
                                        />
                                    </div>
                                </div>


                                {
                                    product !== null &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'products.service_packages.cost'})}</div>
                                            <div className={"price-input-container"}>
                                                <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                                </p>
                                                <input type={"number"} min={0} max={100000} step={0.01}
                                                       className={"form-input"}
                                                       onFocus={(event) => event.target.select()}
                                                       value={cost} onChange={(e) => {
                                                    setCost(e.target.value)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>

                                }
                                {
                                    product !== null &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.price'})}<span
                                                className={"form-required"}>*</span></div>
                                            <div className={"price-input-container"}>
                                                <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                                </p>
                                                <input type={"number"} min={0} max={100000} step={0.01}
                                                       className={"form-input"}
                                                       onFocus={(event) => event.target.select()}
                                                       required value={price} onChange={(e) => {
                                                    setPrice(e.target.value)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>

                                }
                                {
                                    product !== null &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'products.service_packages.type'})}<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={[{
                                                    value: 'per_event',
                                                    label: intl.formatMessage({id: 'settings.taxes.type.per_event'})
                                                }, {
                                                    value: 'per_person',
                                                    label: intl.formatMessage({id: 'settings.taxes.type.per_person'})
                                                }]}
                                                value={type}
                                                onChange={(option) => {
                                                    setType(option.value)
                                                }}
                                                isSearchable={true}
                                                isOptionDisabled={(option) => option.disabled}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    product !== null && type === 'per_event' &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.qty'})}</div>
                                            <input type={"number"} step={1}
                                                   className={"form-input"}
                                                   onFocus={(event) => event.target.select()}
                                                   value={qty} onChange={(e) => {
                                                setQty(e.target.value)
                                            }}/>
                                        </div>
                                    </div>

                                }
                                {
                                    product !== null &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'invoices.details'})}</div>
                                            <input type={"text"}
                                                   className={"form-input"}
                                                   onFocus={(event) => event.target.select()}
                                                   value={details} onChange={(e) => {
                                                setDetails(e.target.value)
                                            }}/>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>

                        <div className={"footer"}>

                            <div className={"row"}>
                                <div className={"col-md-6 col-12 d-flex"}>

                                    {productToEvent !== null && <Button
                                        className={"btn-error mt-4 "}
                                        icon={"fa-regular fa-trash-can ms-3"}
                                        value={intl.formatMessage({id: 'general.buttons.delete'})}
                                        type={'button'}
                                        onClick={() => {
                                            deleteProduct()
                                        }
                                        }
                                    />}
                                </div>


                                <div className={"col-md-6 col-12 d-flex"}>
                                    <Button
                                        className={"btn-primary mt-4 ms-auto"}
                                        icon={"fa-regular fa-floppy-disk"}
                                        value={intl.formatMessage({id: 'general.buttons.save'})}
                                        type={'submit'}
                                        loading={saveLoading}
                                        disabled={saveLoading}
                                    />
                                </div>
                            </div>

                        </div>
                    </form>

                </ModalComp>
            </div>
            <div className="card mt-3">
                <div className={"header"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <p>{intl.formatMessage({id: 'general.sidebar.packages'})}</p>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            {access && !ended && <Button
                                className={"btn-primary d-flex align-items-center ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => {
                                    let ids = servicePackages.map(item1 => item1.service_package.id)
                                    let temp = []
                                    servicesPackagesOptionsAll.forEach(item => {
                                        if (!ids.includes(item.value)) {
                                            temp.push(item)
                                        }
                                    })
                                    setServicesPackagesOptions(temp)
                                    setModalIsOpenServices(true)
                                    setServicePackageToEvent(null)
                                    setServicePackage(null)
                                    setPrice(0)
                                    setCost(0)
                                    setCustomPrice(false)
                                    setDetails('')
                                    setType('per_event')
                                    setTotals([])
                                }}
                            />}
                        </div>

                    </div>

                </div>
                <div className={"content"}>

                    {servicePackages.length > 0 && <div className={"table contracts mb-1"}>
                        <div className={"table-head"}>
                            <div className={"row"}>
                                <div
                                    className={"col-2 table-td"}>
                                </div>
                                <div
                                    className={"col-4 table-td"}>
                                    {intl.formatMessage({id: 'events.title'})}
                                </div>
                                <div
                                    className={"col-2 table-td"}>
                                    {intl.formatMessage({id: 'general.type'})}
                                </div>
                                <div
                                    className={"col-2 table-td"}>
                                    {intl.formatMessage({id: 'general.price'})}
                                </div>

                            </div>

                            <div className={"table-body"}>
                                {
                                    servicePackages.map((item, i) => (
                                        <div className={"row"} key={'pack' + i}
                                             style={{cursor: (access && !ended) ? "pointer" : ''}}
                                             onClick={() => {
                                                 if (access && !ended) {
                                                     setServicesPackagesOptions(servicesPackagesOptionsAll)
                                                     setModalIsOpenServices(true)
                                                     setServicePackageToEvent(item.id)
                                                     setServicePackage(item.service_package.id)
                                                     setPrice(item.price)
                                                     setCost(item.cost)
                                                     setCustomPrice(item.custom_price)
                                                     setDetails(item.details)
                                                     setType(item.type)
                                                     setTotals(item.totals)
                                                 }
                                             }}
                                        >
                                            <div className={"col-2 table-td"}>
                                                {i + 1}.
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {item.service_package.title}
                                            </div>
                                            <div className={"col-2 table-td"}>
                                                {intl.formatMessage({id: 'settings.taxes.type.' + item.type})}
                                            </div>
                                            <div className={"col-2 table-td"}>
                                                {item.price}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>

                <ModalComp
                    title={intl.formatMessage({id: 'general.buttons.' + (servicePackageToEvent === null ? 'add' : 'edit')})}
                    isOpen={modalIsOpenServices}
                    onRequestClose={() => setModalIsOpenServices(false)}
                    className={"add-question-modal"}
                >
                    <form style={{height: '-webkit-fill-available'}} onSubmit={(e) => {
                        verifyServicePackage(e)
                    }}>
                        <div className={"content"} style={{maxHeight: "800px", height: '90%'}}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <Selector
                                            options={servicesPackagesOptions}
                                            value={servicePackage}
                                            key={servicePackage}
                                            onChange={(option) => {
                                                setServicePackage(parseInt(option.value))
                                                setPrice(option.price)
                                                setTotals(option.totals)
                                                setCost(option.cost)
                                                setType(option.type)
                                            }}
                                            isSearchable={true}
                                            isOptionDisabled={(option) => option.disabled}
                                            disabled={servicePackageToEvent !== null}
                                        />
                                    </div>
                                </div>
                                {/*{servicePackage !== null && <div className={"col-12 mb-4"}>

                                    <label>
                                        <input type={"checkbox"}
                                               checked={customPrice}
                                               onChange={() => {
                                                   setCustomPrice(!customPrice)
                                               }}/>

                                        {intl.formatMessage({id: 'events.custom_price_menu'})}
                                    </label>
                                </div>}
                                {
                                    servicePackage !== null && customPrice === true &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'general.price'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"number"} min={0.01} max={100000} step={0.01}
                                                   className={"form-input"}
                                                   onFocus={(event) => event.target.select()}
                                                   required value={price} onChange={(e) => {
                                                setPrice(e.target.value)
                                            }}/>
                                        </div>
                                    </div>

                                }*/}
                                {
                                    servicePackage !== null &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'products.service_packages.type'})}<span
                                                className={"form-required"}>*</span></div>
                                            <Selector
                                                options={[{
                                                    value: 'per_event',
                                                    label: intl.formatMessage({id: 'settings.taxes.type.per_event'})
                                                }, {
                                                    value: 'per_person',
                                                    label: intl.formatMessage({id: 'settings.taxes.type.per_person'})
                                                }]}
                                                value={type}
                                                onChange={(option) => {
                                                    setType(option.value)
                                                }}
                                                isSearchable={true}
                                                isOptionDisabled={(option) => option.disabled}
                                            />
                                        </div>
                                    </div>
                                }

                                {
                                    servicePackage !== null &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'invoices.details'})}</div>
                                            <input type={"text"}
                                                   className={"form-input"}
                                                   onFocus={(event) => event.target.select()}
                                                   value={details} onChange={(e) => {
                                                setDetails(e.target.value)
                                            }}/>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>

                        <div className={"footer"}>

                            <div className={"row"}>
                                <div className={"col-md-6 col-12 d-flex"}>

                                    {servicePackageToEvent !== null && <Button
                                        className={"btn-error mt-4 "}
                                        icon={"fa-regular fa-trash-can ms-3"}
                                        value={intl.formatMessage({id: 'general.buttons.delete'})}
                                        type={'button'}
                                        onClick={() => {
                                            deleteServicePackage()
                                        }
                                        }
                                    />}
                                </div>


                                <div className={"col-md-6 col-12 d-flex"}>
                                    <Button
                                        className={"btn-primary mt-4 ms-auto"}
                                        icon={"fa-regular fa-floppy-disk"}
                                        value={intl.formatMessage({id: 'general.buttons.save'})}
                                        type={'submit'}
                                        loading={saveLoading}
                                        disabled={saveLoading}
                                    />
                                </div>
                            </div>

                        </div>
                    </form>

                </ModalComp>
            </div>
            <ModalComp
                title={intl.formatMessage({id: 'products.product_is_out_of_stock'})}
                isOpen={showModalOutOfStockProduct}
                className={"sign-contract"}
                close={false}
            >
                <div className={"content"}>

                    <div>
                        {intl.formatMessage({id: 'products.product_is_out_of_stock_what_to_continue'})}
                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-12"}>
                            <Button
                                className={"btn-light btn-error mt-4"}
                                value={intl.formatMessage({id: 'general.no'})}
                                onClick={() => {
                                    setShowModalOutOfStockProduct(false)
                                }}
                                type={'button'}
                            />
                        </div>
                        <div className={"col-md-6 col-12 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                type={'button'}
                                onClick={() => {
                                    saveProduct()
                                }}
                            />
                        </div>
                    </div>

                </div>
            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'products.package_is_out_of_stock'})}
                isOpen={showModalOutOfStockPackage}
                className={"sign-contract"}
                close={false}
            >
                <div className={"content"}>

                    <div>
                        {intl.formatMessage({id: 'products.package_is_out_of_stock_what_to_continue'})}
                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-12"}>
                            <Button
                                className={"btn-light btn-error mt-4"}
                                value={intl.formatMessage({id: 'general.no'})}
                                onClick={() => {
                                    setShowModalOutOfStockPackage(false)
                                }}
                                type={'button'}
                            />
                        </div>
                        <div className={"col-md-6 col-12 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                type={'button'}
                                loading={saveLoading}
                                disabled={saveLoading}
                                onClick={() => {
                                    saveServicePackage()
                                }}
                            />
                        </div>
                    </div>

                </div>
            </ModalComp>

        </>
    );
}

