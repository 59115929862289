import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Selector from "../../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ModalComp from "../../../../../../components/ModalComp/ModalComp";

export default function DrinkMenu() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const currency = localStorage.getItem('currency')
    let params = useParams();
    const navigate = useNavigate()
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const [drinkMenus, setDrinkMenus] = useState()
    const [menusOptionsAll, setMenusOptionsAll] = useState([])
    const [menusOptions, setMenusOptions] = useState([])

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [drinkMenuToEvent, setDrinkMenuToEvent] = useState(null)
    const [drinkMenu, setDrinkMenu] = useState(null)
    const [isCustom, setIsCustom] = useState(false)
    const [eventType, setEventType] = useState()
    const [customPrice, setCustomPrice] = useState(false)
    const [price, setPrice] = useState(0)
    const [priceNonAlcoholoc, setPriceNonAlcoholoc] = useState(0)

    const [loading, setLoading] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const {context, ended} = useOutletContext()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setEventType(response.data.event_type)
                getDrinkMenus(response.data.event_type)
                if (response.data.drink_menu_to_events) {
                    setDrinkMenus(response.data.drink_menu_to_events)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getDrinkMenus = async (type, menusAdded) => {
        axios.post(process.env.REACT_APP_API + '/drink-menus/list/0',
            {}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                var items = [];
                response.data.menus.forEach((item) => {
                    let eventTypes = item.event_types.map(item2 => item2.title)
                    if (eventTypes.includes(type)) {
                        items.push({
                            value: item.id,
                            label: item.title,
                            price_non_alcoholic: item.price_non_alcoholic,
                            price_alcoholic: item.price_alcoholic
                        })
                    }
                })
                setMenusOptions(items)
                setMenusOptionsAll(items)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }


    const save = async (e) => {
        e.preventDefault();
        if (drinkMenu === null) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        let payload = {
            drink_menu_to_event: drinkMenuToEvent,
            drink_menu: drinkMenu,
            custom_price: customPrice,
            price_alcoholic: price,
            price_non_alcoholic: priceNonAlcoholoc
        }
        await axios.put(process.env.REACT_APP_API + '/events/drink-menu/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpen(false)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const deleteItem = async () => {
        let payload = {
            drink_menu_to_event: drinkMenuToEvent,
        }
        await axios.post(process.env.REACT_APP_API + '/events/drink-menu/delete/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                getData()
                setModalIsOpen(false)
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const exportMenus = () => {
        setLoadingDownload(true)
        fetch(process.env.REACT_APP_API + '/event-drink-menus-excel/' + params.id, {
            method: 'POST',
            body: JSON.stringify({language: localStorage.getItem('language') ?? 'ro'}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "menus.xlsx";

                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))
    }

    return (
        <>
            <Button
                className={"btn-secondary mb-2"}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.download_drink_menus_excel'})}
                type={"button"}

                loading={loadingDownload}
                disabled={loadingDownload}
                onClick={() => exportMenus()}
            />
            <div className="card">
                <div className={"header"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <p>{intl.formatMessage({id: 'events.event_drink_menu'})}</p>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            {access && !ended && <Button
                                className={"btn-primary d-flex align-items-center ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => {
                                    let ids = drinkMenus.map(item1 => item1.drink_menu.id)
                                    let temp = []
                                    menusOptionsAll.forEach(item => {
                                        if (!ids.includes(item.value)) {
                                            temp.push(item)
                                        }
                                    })
                                    setMenusOptions(temp)
                                    setModalIsOpen(true)
                                    setDrinkMenuToEvent(null)
                                    setDrinkMenu(null)
                                    setCustomPrice(false)
                                    setIsCustom(false)
                                    setPrice(0)
                                    setPriceNonAlcoholoc(0)
                                }}
                            />}
                        </div>

                    </div>

                </div>
                <div className={"content"}>

                    <div className={"table contracts mb-1"}>
                        <div className={"table-head"}>
                            <div className={"row"}>
                                <div
                                    className={"col-2 table-td"}>
                                </div>
                                <div
                                    className={"col-4 table-td"}>
                                    {intl.formatMessage({id: 'events.title'})}
                                </div>
                                <div
                                    className={"col-3 table-td"}>
                                    {intl.formatMessage({id: 'general.qty'})}
                                </div>
                                <div
                                    className={"col-3 table-td"}>
                                    {intl.formatMessage({id: 'events.drinks.custom_menu'})}
                                </div>

                            </div>

                            <div className={"table-body"}>
                                {
                                    drinkMenus.map((menu, i) => (
                                        <div className={"row"} key={i}
                                             style={{cursor: access && !ended ? "pointer" : ''}}
                                             onClick={() => {
                                                 if (access && !ended) {
                                                     setMenusOptions(menusOptionsAll)
                                                     setModalIsOpen(true)
                                                     setDrinkMenuToEvent(menu.id)
                                                     setDrinkMenu(menu.drink_menu.id)
                                                     setCustomPrice(menu.custom_price)
                                                     setIsCustom(menu.drink_menu.is_custom)
                                                     setPrice(menu.custom_price ? menu.price_alcoholic : menu.drink_menu.price_alcoholic)
                                                     setPriceNonAlcoholoc(menu.custom_price ? menu.price_non_alcoholic : menu.drink_menu.price_non_alcoholic)

                                                 }
                                             }}
                                        >
                                            <div className={"col-2 table-td"}>
                                                {i + 1}.
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {menu.drink_menu.title}
                                            </div>
                                            <div className={"col-3 table-td"}>
                                                {menu.qty}
                                            </div>
                                            <div className={"col-3 table-td"}>
                                                {}
                                                <i className={"fa-solid " + (menu.drink_menu.is_custom ? 'fa-check' : 'fa-xmark')}
                                                   color={menu.drink_menu.is_custom ? 'green' : 'red'}/>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>

                <ModalComp
                    title={intl.formatMessage({id: 'events.' + (drinkMenuToEvent === null ? 'add_drink_menu' : 'edit_drink_menu')})}
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    className={"add-question-modal"}
                >
                    <form style={{height: '-webkit-fill-available'}} onSubmit={(e) => {
                        save(e)
                    }}>
                        <div className={"content"} style={{maxHeight: "800px", height: '90%'}}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    {
                                        userAccess.menus && (
                                            drinkMenuToEvent !== null ?
                                                (isCustom ? <Button
                                                        className={"btn-secondary mt-2 mb-4"}
                                                        style={{width: '100%'}}
                                                        icon={"fa-solid fa-pen"}
                                                        value={intl.formatMessage({id: 'general.buttons.edit'})}
                                                        type={'button'}
                                                        onClick={() => navigate('/events/' + params.id + '/drink-menu/edit/' + drinkMenu + '/' + eventType)}
                                                    />
                                                    :
                                                    <Button
                                                        className={"btn-secondary mt-2 mb-4 ms-auto"}
                                                        style={{width: '100%'}}
                                                        icon={"fa-solid fa-pen"}
                                                        value={intl.formatMessage({id: 'general.buttons.edit'})}
                                                        type={'button'}
                                                        onClick={() => navigate('/events/' + params.id + '/drink-menu/add/' + drinkMenu + '/' + eventType)}
                                                    />)
                                                :
                                                <Button
                                                    className={"btn-secondary mt-2 mb-4 ms-auto"}
                                                    style={{width: '100%'}}
                                                    icon={"fa-solid fa-pen"}
                                                    value={intl.formatMessage({id: 'general.buttons.add_custom_menu'})}
                                                    type={'button'}
                                                    onClick={() => navigate('/events/' + params.id + '/drink-menu/add/0/' + eventType)}
                                                />
                                        )

                                    }
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <Selector
                                            options={menusOptions}
                                            value={drinkMenu}
                                            key={drinkMenu}
                                            onChange={(option) => {
                                                setDrinkMenu(parseInt(option.value))
                                                setPrice(option.price_alcoholic)
                                                setPriceNonAlcoholoc(option.price_non_alcoholic)
                                            }}
                                            isSearchable={true}
                                            isOptionDisabled={(option) => option.disabled}
                                            disabled={drinkMenuToEvent !== null}
                                        />
                                    </div>
                                </div>
                                {drinkMenu !== null && <div className={"col-12 mb-4"}>

                                    <label>
                                        <input type={"checkbox"}
                                               checked={customPrice}
                                               onChange={() => {
                                                   setCustomPrice(!customPrice)
                                               }}/>

                                        {intl.formatMessage({id: 'events.custom_price_menu'})}
                                    </label>
                                </div>}
                                {
                                    customPrice === true && drinkMenu !== null && <>
                                        <div className={"col-12 col-md-6 mb-4"}>

                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'menus.menu_price_non_alcoholic'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <div className={"price-input-container"}>
                                                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                                    </p>
                                                    <input type={"number"} min={0.01} max={100000} step={0.01}
                                                           className={"form-input"}
                                                           onFocus={(event) => event.target.select()}
                                                           required value={priceNonAlcoholoc} onChange={(e) => {
                                                        setPriceNonAlcoholoc(e.target.value)
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 col-md-6 mb-4"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'menus.menu_price_alcoholic'})}<span
                                                    className={"form-required"}>*</span></div>
                                                <div className={"price-input-container"}>
                                                    <p>{intl.formatMessage({id: 'settings.currency.' + currency})}
                                                    </p>
                                                    <input type={"number"} min={0.01} max={100000} step={0.01}
                                                           className={"form-input"}
                                                           onFocus={(event) => event.target.select()}
                                                           required value={price} onChange={(e) => {
                                                        setPrice(e.target.value)
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        <div className={"footer"}>

                            <div className={"row"}>
                                <div className={"col-md-6 col-12 d-flex"}>

                                    {drinkMenuToEvent !== null && <Button
                                        className={"btn-error mt-4 "}
                                        icon={"fa-regular fa-trash-can ms-3"}
                                        value={intl.formatMessage({id: 'general.buttons.delete'})}
                                        type={'button'}
                                        onClick={() => {
                                            deleteItem()
                                        }
                                        }
                                    />}
                                </div>


                                <div className={"col-md-6 col-12 d-flex"}>
                                    <Button
                                        className={"btn-primary mt-4 ms-auto"}
                                        icon={"fa-regular fa-floppy-disk"}
                                        value={intl.formatMessage({id: 'general.buttons.save'})}
                                        type={'submit'}
                                        loading={saveLoading}
                                        disabled={saveLoading}
                                    />
                                </div>
                            </div>

                        </div>
                    </form>

                </ModalComp>
            </div>
        </>
    );
}

