import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import Selector from "../../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";
import ModalMentions from "../../../../../../components/ModalMentions/ModalMentions";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import {GOOGLE_API_KEY} from "../../../../../../utils/constants";
import ModalCancelEvent from "../../../../../../components/ModalCancelEvent/ModalCancelEvent";

export default function DetailsForEventPlanner() {

    const intl = useIntl();
    const inputRef = useRef()
    const jwt = localStorage.getItem('jwt')
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events
    let params = useParams();

    const [eventName, setEventName] = useState()
    const [event, setEvent] = useState()
    const [eventType, setEventType] = useState()
    const [eventTypes, setEventTypes] = useState([])
    const [eventStatus, setEventStatus] = useState()
    const [tags, setTags] = useState([])
    const [tagsOptions, setTagsOptions] = useState([])
    const [source, setSource] = useState(0)
    const [sources, setSources] = useState([])
    const [eventDate, setEventDate] = useState()
    const [installationDate, setInstallationDate] = useState()
    const [numberOfGuests, setNumberOfGuests] = useState(null)
    const [loadingAddMentions, setLoadingAddMentions] = useState(false)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [closeLoading, setCloseLoading] = useState(false)

    const [timeStart, setTimeStart] = useState(null)
    const [deleteAfter, setDeleteAfter] = useState('')
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [duration, setDuration] = useState(1)
    const [canConfirm, setCanConfirm] = useState(false)
    const [message, setMessage] = useState('')
    const [file, setFile] = useState(null)
    const [fileUrl, setFileUrl] = useState('')
    const navigate = useNavigate()

    const [modalMentionsIsOpen, setModalMentionsIsOpen] = useState(false)
    const [modalCanceledIsOpen, setModalCanceledIsOpen] = useState(false)
    const [canceledMessage, setCanceledMessage] = useState('')
    const [mentionsHistory, setMentionsHistory] = useState([])
    const [mentions, setMentions] = useState('')
    const [searchedAddress, setSearchedAddress] = useState('')
    const [textLocation, setTextLocation] = useState('')
    const {context, ended} = useOutletContext()

    useEffect(() => {
        if (access)
            getEventTypes()
        else
            getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getEventTypes = async () => {
        axios.get(process.env.REACT_APP_API + '/event-type-for-event-planners', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i=0; i < response.data.length; i++){
                    temp.push({
                        value: response.data[i].id,
                        label: response.data[i].title
                    })
                }
                setEventTypes(temp)
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setEvent(response.data)
                    setEventName(response.data.title)
                    setEventType(response.data.event_type_for_event_planner)
                    if (response.data.text_location)
                        setTextLocation(response.data.text_location)
                    setEventStatus(response.data.status)
                    setTags(response.data.tags.map(item => item.id))
                    setSource(response.data.source)
                    setNumberOfGuests(response.data.number_of_guests)
                    setEventDate(new Date(response.data.start))
                    setInstallationDate(response.data.installation_date ? new Date(response.data.installation_date) : '')
                    setDeleteAfter(response.data.delete_after ? new Date(response.data.delete_after) : '')
                    setTimeStart(new Date(response.data.start))
                    setDuration((parseInt(moment(response.data.end).unix()) -
                        parseInt(moment(response.data.start).unix())) / (60 * 60 * (response.data.event_type === 'festival' ? 24 : 1)))
                    setCanConfirm(response.data.can_confirm)
                    if (!response.data.can_confirm && response.data.status !== ' confirmed') {
                        setMessage(intl.formatMessage({id: 'events.confirm_event_message_1'}))
                    }

                    if (response.data.hall_plan)
                        setFileUrl(process.env.REACT_APP_API + response.data.hall_plan.url)
                }
                getTags()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getTags = async () => {
        axios.get(process.env.REACT_APP_API + '/event-tags', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.tags.length; i++) {
                    temp.push({
                        value: response.data.tags[i].id,
                        label: response.data.tags[i].title
                    })
                }
                setTagsOptions(temp)
                getSources()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getSources = async () => {
        axios.get(process.env.REACT_APP_API + '/event-sources', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.sources.forEach(item => {
                    temp.push({
                        value: item.id,
                        label: item.title
                    })
                })
                setSources(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    const save = async () => {
        if (eventType === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        if (eventStatus === 'canceled' && canceledMessage === ''){
            setModalCanceledIsOpen(true)
            return
        }
        setSaveLoading(true)
        let start = moment(eventDate).startOf('day');
        let end = moment(eventDate).endOf('day');

        if (timeStart) {
            start.hour(moment(timeStart).hour());
            start.minute(moment(timeStart).minute());

            let hours = parseInt(moment(timeStart).hour()) + parseInt(event.event_type === 'festival' ? 24 * duration : duration)
            end.hour(hours % 24);

            end.minute(moment(timeStart).minute());
            end.second(0)
            end.utc(false)
            start.utc(false)
            if (hours >= 24) {
                end.add(parseInt(hours / 24), 'day');
            }

            end.millisecond(0)
        }

        end.utc(false)
        start.utc(false)
        let formData = new FormData()
        formData.append('data', JSON.stringify({
            title: eventName,
            delete_after: deleteAfter,
            start: start.toISOString(),
            end: end.toISOString(),
            status: eventStatus,
            text_location: textLocation,
            event_type_for_event_planner: eventType,
            installation_date: moment(installationDate).toISOString(),
            number_of_guests: numberOfGuests,
            cancellation_reason: canceledMessage,
            tags,
            source
        }))
        if (file) {
            formData.append('hall_plan', file)
        }

        await axios.put(process.env.REACT_APP_API + '/events/details/' + params.id, formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                getData()

                if (eventStatus === 'canceled')
                    window.location.reload()
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const closeEvent = async () => {
        setCloseLoading(true)

        await axios.get(process.env.REACT_APP_API + '/events/close/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                window.location.reload()
            })
            .catch(err => {
                setCloseLoading(false)
                errorParser(err, intl)
            })
    }
    const deleteEvent = async () => {
        await axios.delete(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                navigate('/calendar')
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setDeleteLoading(false)
    }


    const addMentions = async (e) => {
        e.preventDefault();
        setLoadingAddMentions(true)
        let ment = mentionsHistory;
        ment.push({
            user: 'owner',
            note: mentions,
            date: moment(),
            user_id: event.user.id

        })
        let payload = {
            mentions: ment,
            user: 'owner'
        }
        axios.post(process.env.REACT_APP_API + '/events/add-mentions/' + event.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then((response) => {
            getData();
            //     setModalMentionsIsOpen(false)
            setMentions('')
            setLoadingAddMentions(false)
        })
            .catch(err => {
                errorParser(err, intl)
                setLoadingAddMentions(false)
            })
    }


    return (
        <div className="card">
            <div className={"header "}>

                <div className={"row"}>
                    <div className={"col-md-4 col-sm-12"}>
                        <p className={"d-inline-flex"}>{intl.formatMessage({id: 'events.event_details'})}
                        </p>
                    </div>

                    {access && !ended && <div className={"col-md-8 col-sm-12"}>

                        <Button
                            className={"btn-primary ms-2"}
                            style={{float: 'right'}}
                            disabled={closeLoading}
                            loading={closeLoading}
                            value={intl.formatMessage({id: 'events.close_event'})}
                            onClick={() => {
                                closeEvent()
                            }}
                        />
                        <Button
                            className={"btn-primary "}
                            style={{float: 'right'}}
                            value={intl.formatMessage({id: 'contracts.client.add_mentions'})}
                            onClick={() => {
                                context.setContentUpdated(true)
                                setMentionsHistory(event.mentions ?? [])
                                setModalMentionsIsOpen(true)
                            }}
                        />
                    </div>}

                </div>

            </div>

            <form onSubmit={(e) => {
                e.preventDefault()
                save()
            }}>
                <div className={"content"}>
                    <div className={"row"}>

                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_name'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"}
                                       value={eventName} required
                                       disabled={!access || ended}
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setEventName(e.target.value)
                                       }}
                                />
                            </div>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'menus.event_type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    disabled={!access || ended}
                                    options={eventTypes}
                                    value={eventType}
                                    key={eventType}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setEventType(option.value)
                                    }}
                                />
                            </div>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_status'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    disabled={!access || ended || event.status === 'confirmed'}
                                    options={[{
                                        value: 'booked',
                                        label: intl.formatMessage({id: 'events.status.booked'})
                                    }, {
                                        disabled: !canConfirm,
                                        value: 'confirmed',
                                        label: intl.formatMessage({id: 'events.status.confirmed'})
                                    }, {
                                        value: 'canceled',
                                        label: intl.formatMessage({id: 'events.status.canceled'})
                                    }]}
                                    value={eventStatus}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setEventStatus(option.value)
                                    }}
                                    isSearchable={false}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                                {message && <div className={"alert alert-warning mt-2"}>
                                    {message}
                                </div>}
                            </div>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.tags'})}</div>
                                <Selector
                                    options={tagsOptions}
                                    value={tags}
                                    onChange={(option) =>
                                        setTags(Array.from(option, option => option.value))}
                                    isSearchable={false}
                                    disabled={!access || ended}
                                    isOptionDisabled={(option) => option.disabled}
                                    isMulti
                                />
                            </div>

                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.source'})}</div>
                                <Selector
                                    options={sources}
                                    value={source}
                                    disabled={!access || ended}
                                    onChange={(option) =>
                                        setSource(option.value)}
                                    isSearchable={false}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>

                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.number_of_guests'})}</div>
                                <input type={"number"} className={"form-input"}
                                       value={numberOfGuests}
                                       disabled={!access || ended}
                                       onFocus={(event) => event.target.select()}
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setNumberOfGuests(e.target.value)
                                       }}
                                />
                            </div>
                            {
                                eventStatus === 'booked' &&
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'events.delete_after'})}</div>
                                    <DatePicker
                                        disabled={!access || ended}
                                        locale={moment.locale()}
                                        selected={deleteAfter}
                                        minDate={new Date()}
                                        onChange={(date) => {
                                            context.setContentUpdated(true)
                                            setDeleteAfter(date)
                                        }}
                                        requires
                                        className={"form-input"}
                                    />
                                </div>
                            }
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_date'})}<span
                                    className={"form-required"}>*</span></div>
                                <DatePicker
                                    disabled={!access || ended}
                                    locale={moment.locale()}
                                    className={"form-input"}
                                    selected={eventDate}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                        context.setContentUpdated(true)
                                        setEventDate(date)
                                    }}
                                    required
                                />
                            </div>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.time_start'})}</div>
                                <DatePicker
                                    disabled={!access || ended}
                                    timeCaption={intl.formatMessage({id: 'events.time'})}
                                    locale={moment.locale()}
                                    selected={timeStart}
                                    onChange={(date) => {
                                        context.setContentUpdated(true)
                                        setTimeStart(date)
                                    }}
                                    showTimeSelectOnly showTimeSelect dateFormat={"HH:mm"} timeFormat="HH:mm"
                                    className={"form-input"}/>
                            </div>

                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'events.duration' + (event.event_type === 'festival' ? '_days' : '')})}</div>
                                <input type={"number"} className={"form-input"} min={1}
                                       max={['wedding', 'christening'].includes(event.event_type) ? 12 : 72}
                                       value={duration} required
                                       disabled={!access || ended}
                                       onFocus={(event) => event.target.select()}
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setDuration(e.target.value)
                                       }}
                                />
                            </div>

                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.event_installation_date'})}</div>
                                <DatePicker
                                    disabled={!access || ended}
                                    locale={moment.locale()}
                                    className={"form-input"}
                                    selected={installationDate}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                        context.setContentUpdated(true)
                                        setInstallationDate(date)
                                    }}
                                />
                            </div>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.location'})}{
                                     eventStatus === 'confirmed' && <span className={"form-required"}>*</span>
                                }</div>
                                <ReactGoogleMapLoader
                                    params={{
                                        key: GOOGLE_API_KEY,
                                        libraries: "places,geocode",
                                    }}
                                    render={googleMaps =>
                                        googleMaps && (
                                            <ReactGooglePlacesSuggest
                                                googleMaps={googleMaps}
                                                autocompletionRequest={{
                                                    input: searchedAddress,
                                                    // Optional options
                                                    // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                                                }}
                                                // Optional props
                                                onNoResult={() => {
                                                    console.log("No results for ", searchedAddress)
                                                }}
                                                onSelectSuggest={(geocodedPrediction, originalPrediction) => {
                                                    console.log(geocodedPrediction, originalPrediction) // eslint-disable-line
                                                    setSearchedAddress('')
                                                    setTextLocation(geocodedPrediction.formatted_address)
                                                }}
                                                onStatusUpdate={(status) => {
                                                    console.log(status)
                                                }}
                                                textNoResults="My custom no results text" // null or "" if you want to disable the no results item
                                                customRender={prediction => (
                                                    <div className="customWrapper">
                                                        {prediction
                                                            ? prediction.description
                                                            : "My custom no results text"}
                                                    </div>
                                                )}
                                            >
                                                <input
                                                    type="text"
                                                    value={textLocation}
                                                    className={"form-input"}
                                                    required={eventStatus === 'confirmed'}
                                                    placeholder="Search a location"
                                                    onChange={(e) => {
                                                        setSearchedAddress(e.target.value)
                                                        setTextLocation(e.target.value)
                                                    }}
                                                />
                                            </ReactGooglePlacesSuggest>
                                        )
                                    }
                                />
                            </div>
                            {access && !ended && <div className={"form-control"}>

                                <div className={"form-label"}>{intl.formatMessage({id: 'events.hall_plan'})}</div>
                                <div className={"tab-content p-2"} style={{borderRadius: '8px'}}>
                                    <div>
                                        <input type={"file"}
                                               accept=".doc,.docx,.pdf"
                                               ref={inputRef}
                                               style={{display: "none"}}
                                               multiple={false}
                                               onChange={(e) => {
                                                   if (e.target.files[0] && access && !ended) {
                                                       context.setContentUpdated(true)
                                                       setFile(e.target.files[0])
                                                   }
                                               }}
                                        />
                                        <div className={"upload-image-comp"}
                                             onClick={() => access && !ended && inputRef.current.click()}>
                                            {
                                                file !== null ?
                                                    <div className={"preview"}>
                                                        <img src={''} alt={""}/>
                                                        <div>
                                                            <i style={{fontSize: '2rem'}}
                                                               className="fa-regular fa-file"></i>
                                                            <div className={"title"}>{file.name}</div>
                                                        </div>
                                                    </div>
                                                    :

                                                    <>
                                                        <i className="fa-regular fa-images icon"></i>
                                                        <div
                                                            className={"title"}>{intl.formatMessage({id: "general.upload.excel.title"})}</div>
                                                        <div
                                                            className={"info"}>{intl.formatMessage({id: "general.upload.pdf_doc_docx.info"})}</div>
                                                    </>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            <div className={"form-control"}>
                                {fileUrl !== '' && <a
                                    className={"btn btn-primary mt-2 d-flex justify-content-center align-items-center"}
                                    type={'button'}
                                    href={fileUrl}
                                    rel={"noreferrer"}
                                    target={"_blank"}
                                ><i className={"fa-solid fa-trash me-2"}/> {intl.formatMessage({id: 'general.buttons.download_hall_plan'})}
                                </a>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        {event.user !== null && <div className={"col-12 mb-2"}>
                            {intl.formatMessage({id: 'events.added_by'})}: {event.user.first_name} {event.user.last_name}
                        </div>}
                        <div className={"col-md-6 col-sm-12 d-flex"}>
                            {
                                access && !ended &&
                                <Button
                                    className={"btn-primary mt-2"}
                                    icon={"fa-solid fa-trash"}
                                    value={intl.formatMessage({id: 'general.buttons.delete'})}
                                    type={'button'}
                                    loading={deleteLoading}
                                    onClick={() => {
                                        setDeleteLoading(true)
                                        deleteEvent()
                                    }}
                                />
                            }
                        </div>
                        <div className={"col-md-6 col-sm-12 d-flex"}>
                            {access && !ended && <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                type={'submit'}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />}
                        </div>

                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </form>

            <ModalMentions
                modalMentionsIsOpen={modalMentionsIsOpen}
                setModalMentionsIsOpen={setModalMentionsIsOpen}
                mentionsHistory={mentionsHistory}
                mentions={mentions}
                setMentions={setMentions}
                addMentions={addMentions}
                user={'owner'}
                loadingAddMentions={loadingAddMentions}
                contract={{event: event}}
            />
            <ModalCancelEvent
                message={canceledMessage}
                setMessage={setCanceledMessage}
                loading={saveLoading}
                save={save}
                modalIsOpen={modalCanceledIsOpen}
                setModalIsOpen={setModalCanceledIsOpen}
            />
        </div>
    );
}

