import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../../components/ErrorParser/ErrorParser";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../../components/elements/LoadingComp/LoadingComp";

export default function MenusForEventPlanner() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const navigate = useNavigate()
    const userAccess = JSON.parse(localStorage.getItem('access'))
    const access = userAccess && userAccess.events

    const [foodMenus, setFoodMenus] = useState()
    const [drinkMenus, setDrinkMenus] = useState()
    const {context, ended} = useOutletContext()
    const [loading, setLoading] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState(false)

    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/events/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.food_menu_to_events) {
                    setFoodMenus(response.data.food_menu_to_events)
                }
                if (response.data.drink_menu_to_events) {
                    setDrinkMenus(response.data.drink_menu_to_events)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    const exportMenus = () => {
        setLoadingDownload(true)
        fetch(process.env.REACT_APP_API + '/event-menus-excel/' + params.id, {
            method: 'POST',
            body: JSON.stringify({language: localStorage.getItem('language') ?? 'ro'}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "menus.xlsx";

                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))
    }
    return (
        <>

            <Button
                className={"btn-secondary mb-2"}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.download_menus_excel'})}
                type={"button"}

                loading={loadingDownload}
                disabled={loadingDownload}
                onClick={() => exportMenus()}
            />
            <Button
                className={"btn-secondary mb-2 ms-2"}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.download_drink_menus_excel'})}
                type={"button"}

                loading={loadingDownload}
                disabled={loadingDownload}
                onClick={() => exportMenus()}
            />
            <div className="card">
                <div className={"header"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <p>{intl.formatMessage({id: 'events.event_food_menu'})}</p>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            {access && !ended && <Button
                                className={"btn-primary d-flex align-items-center ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => navigate('/events/' + params.id + '/menus/food/0/0/0')}
                            />}
                        </div>

                    </div>

                </div>
                <div className={"content"}>
                                 <div className={"table contracts mb-1"}>
                        <div className={"table-head"}>
                            <div className={"row"}>
                                <div
                                    className={"col-2 table-td"}>
                                </div>
                                <div
                                    className={"col-4 table-td"}>
                                    {intl.formatMessage({id: 'events.title'})}
                                </div>
                                <div
                                    className={"col-3 table-td"}>
                                    {intl.formatMessage({id: 'general.type'})}
                                </div>
                                <div
                                    className={"col-3 table-td"}>
                                    {intl.formatMessage({id: 'general.qty'})}
                                </div>
                            </div>

                            <div className={"table-body"}>
                                {
                                    foodMenus.map((menu, i) => (
                                        <div className={"row"}
                                             key={i}
                                             style={{cursor: (access && !ended) ? "pointer" : ''}}
                                             onClick={() => {
                                                 if (access && !ended) {
                                                     navigate('/events/' + params.id + '/menus/food/' + + menu.food_menu.id + '/' + menu.id + '/' + menu.qty)
                                                 }
                                             }}
                                        >
                                            <div className={"col-2 table-td"}>
                                                {i + 1}.
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {menu.food_menu.title}
                                            </div>
                                            <div
                                                className={"col-3 table-td"}>
                                                {intl.formatMessage({id: 'events.food_menus.type.' + menu.type})}
                                            </div>
                                            <div className={"col-3 table-td"}>
                                                {menu.qty}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                </div>
            </div>

            <div className="card mt-4">
                <div className={"header"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <p>{intl.formatMessage({id: 'events.event_drink_menu'})}</p>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            {access && !ended && <Button
                                className={"btn-primary d-flex align-items-center ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => navigate('/events/' + params.id + '/menus/drink/0/0/0')}
                            />}
                        </div>

                    </div>

                </div>
                <div className={"content"}>

                    <div className={"table contracts mb-1"}>
                        <div className={"table-head"}>
                            <div className={"row"}>
                                <div
                                    className={"col-2 table-td"}>
                                </div>
                                <div
                                    className={"col-6 table-td"}>
                                    {intl.formatMessage({id: 'events.title'})}
                                </div>
                                <div
                                    className={"col-4 table-td"}>
                                    {intl.formatMessage({id: 'general.qty'})}
                                </div>

                            </div>

                            <div className={"table-body"}>
                                {
                                    drinkMenus.map((menu, i) => (
                                        <div className={"row"} key={i}
                                             style={{cursor: access && !ended ? "pointer" : ''}}
                                             onClick={() => {
                                                 if (access && !ended) {
                                                     navigate('/events/' + params.id + '/menus/drink/' + menu.drink_menu.id + '/' + menu.id + '/' + menu.qty)
                                                 }
                                             }}
                                        >
                                            <div className={"col-2 table-td"}>
                                                {i + 1}.
                                            </div>
                                            <div className={"col-6 table-td"}>
                                                {menu.drink_menu.title}
                                            </div>
                                            <div className={"col-4 table-td"}>
                                                {menu.qty}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        {
                            window.alityAppView &&
                            <div className={"col-12"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {

                                        context.setLeavePage(true)
                                        context.setNextLink('/calendar')
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

