import Button from "../../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {useParams} from "react-router-dom";

export default function ExportLogistics() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [loadingDownload, setLoadingDownload] = useState(false)

    const exportExcel = () => {
        setLoadingDownload(true)
        fetch(process.env.REACT_APP_API + '/event-logistics-excel/' + params.id, {
            method: 'POST',
            body: JSON.stringify({language: localStorage.getItem('language') ?? 'ro'}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "logistics.xlsx";

                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))
    }
    return (
        <Button
            className={"btn-secondary mb-2 w-100"}
            icon={"fa-regular fa-floppy-disk"}
            value={intl.formatMessage({id: 'general.download_logistics_excel'})}
            type={"button"}

            loading={loadingDownload}
            disabled={loadingDownload}
            onClick={() => exportExcel()}
        />
    );
}

