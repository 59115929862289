import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import Button from "../../../../../../components/elements/Button/Button";

export default function ExportEventSheet() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    let params = useParams();
    const [loadingDownload, setLoadingDownload] = useState(false)
    const exportSheet = () => {
        setLoadingDownload(true)
        fetch(process.env.REACT_APP_API + '/event-sheet-excel/' + params.id, {
            method: 'POST',
            body: JSON.stringify({language: localStorage.getItem('language') ?? 'ro'}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "event.xlsx";

                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))
    }

    return (
        <div className={"event-sheet"}>

            {
                window.alityAppView &&
                <div className={"col-12"}>
                    <Button
                        className={"btn-light mt-2"}
                        icon={"fa-solid fa-chevron-left"}
                        value={intl.formatMessage({id: 'general.buttons.back'})}
                        onClick={() => {
                            navigate('/calendar')
                        }}
                    />
                </div>
            }
            <Button
                className={"btn-secondary mb-2 ms-auto w-100"}
                icon={"fa-regular fa-floppy-disk"}
                value={intl.formatMessage({id: 'general.buttons.download'})}
                type={"button"}

                loading={loadingDownload}
                disabled={loadingDownload}
                onClick={() => exportSheet()}
            />
        </div>
    );
}
